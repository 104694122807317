import React from "react";

import { BsLinkedin } from "react-icons/bs";
import { ImGithub } from "react-icons/im";

const SocialMedia = () => (
<div className="app__social">

    {/* <a href="https://github.com/sahilgupta3030" target="_blank">
      <div style={{ height: 50, width: 50 }}>
        <ImGithub style={{ height: 22, width: 22 }} />
      </div>
    </a>

    <a href="https://www.linkedin.com/in/sahilgupta3030/" target="_blank">
      <div style={{ height: 50, width: 50 }}>
        <BsLinkedin style={{ height: 22, width: 22 }} />
      </div>
    </a> */}
    
  </div>
);

export default SocialMedia;
