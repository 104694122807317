import react from "../assets/react.png";
import javascript from "../assets/javascript.png";
import MERN from "../assets/MERN.png";
import cpp from "../assets/cpp.png";

import logo from "../assets/logo.png";
import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";

import email from "../assets/email.png";
import mobile from "../assets/mobile.png";

export default {
  react,
  javascript,
  cpp,
  MERN,

  logo,
  profile,
  circle,

  email,
  mobile,
};
